import './Skills.scss'
import Aos from 'aos'
import React, { useEffect } from 'react'
import { Container } from '../../utils/Utils'
import Postman from '../../assets/skills/postman.png'
import ReactPng from '../../assets/skills/react.png'
import Antd from '../../assets/skills/antd.png'
import Zustand from '../../assets/skills/zustand.png'
import Postgresql from '../../assets/skills/postgresql.png'

const skillsData = [
    {
        skill_name: 'HTML',
        img: 'https://cdn-icons-png.flaticon.com/512/732/732212.png'
    },
    {
        skill_name: 'CSS',
        img: 'https://cdn1.iconfinder.com/data/icons/logotypes/32/badge-css-3-1024.png'
    },
    
    {
        skill_name: 'Sass',
        img: 'https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/288_Sass_logo-1024.png'
    },
    {
        skill_name: 'Tailwand',
        img: 'https://adminmart.com/wp-content/uploads/2023/03/tailwindcss.svg'
    },
    {
        skill_name: 'BootStrap',
        img: 'https://camo.githubusercontent.com/d1f34a845e52e79041c1995394dbad253b4177c1549264ae671bc1b58f04f5d9/68747470733a2f2f75706c6f61642e77696b696d656469612e6f72672f77696b6970656469612f636f6d6d6f6e732f7468756d622f622f62322f426f6f7473747261705f6c6f676f2e7376672f3235363070782d426f6f7473747261705f6c6f676f2e7376672e706e67'
    },
    {
        skill_name: 'JavaScript',
        img: 'https://logo-base.com/logo/javascript_js_logo_icon.png'
    },
    {
        skill_name: 'TypeScript',
        img: 'https://w7.pngwing.com/pngs/915/519/png-transparent-typescript-hd-logo.png'
    },
    {
        skill_name: 'Next.Js',
        img: "https://marcbruederlin.gallerycdn.vsassets.io/extensions/marcbruederlin/next-icons/0.1.0/1723747598319/Microsoft.VisualStudio.Services.Icons.Default"
    },
    {
        skill_name: 'React.Js',
        img: ReactPng
    },
    {
        skill_name: 'Vue.Js',
        img: 'https://logos-download.com/wp-content/uploads/2022/12/Vue.js_Logo.png'
    },
    {
        skill_name: 'Redux',
        img: 'https://uploads-ssl.webflow.com/5eacc8815c6b73d93d13b2f5/5ed819a41bec6275eade8334_Redux-p-800.jpeg'
    },
    {
        skill_name: 'Zustand',
        img: Zustand
    },
    // {
    //     skill_name: 'Axios',
    //     img: 'https://cdn.worldvectorlogo.com/logos/axios.svg'
    // },
    {
        skill_name: 'PostgreSQL',
        img: Postgresql
    },
    {
        skill_name: 'MondoDB',
        img: "https://seeklogo.com/images/M/mongodb-logo-D13D67C930-seeklogo.com.png"
    },
    {
        skill_name: 'Postman',
        img: Postman
    },
    {
        skill_name: 'Material UI',
        img: 'https://i.pinimg.com/originals/3c/44/e5/3c44e5f0b2b630dc0e30d8c980b50c08.png'
    },
    {
        skill_name: 'Ant Design',
        img: Antd
    },
    {
        skill_name: 'Pinia',
        img: "https://upload.wikimedia.org/wikipedia/commons/1/1c/Pinialogo.svg"
    },
    {
        skill_name: 'GitHub',
        img: 'https://cdn0.iconfinder.com/data/icons/free-social-media-set/24/github-512.png'
    }

]

const Skills = () => {


    useEffect(() => {
        Aos.init({
            once: true
        })
        
    }, [])

    return (
       <div id='skills' className="skills">
         <Container>
            <div className='skills-wrapper'>
                <h2 className='skill-title'>Skills</h2>
        

                <div className="skill__items-wrapper">
                    {
                        skillsData.map((skill, index) =>
                        <div data-aos='zoom-in' data-aos-once='true' key={index} className="skill-item">
                            <img src={skill.img} alt={skill.skill_name} />
                            <p>{skill.skill_name}</p>
                        </div>
                    
                    )
                    }
                </div>

            </div>
        </Container>
       </div>
    )
}

export default Skills
