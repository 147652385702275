import React from 'react'
import "./Utils.scss"

const Container = ({children}) => {
  return (
    <div className='container'>
            {children}
    </div>
  )
}

export  {Container}
