import "./Hero.scss";
import Typewriter from 'typewriter-effect';
import { Container } from "../../utils/Utils";
import Person from "../../assets/images/person.png";
import Resume from '../../assets/pdf/mehrojbek-resume.pdf';
import { useEffect } from "react";
import { FaGithub, FaInstagram, FaLinkedin, FaTelegram } from 'react-icons/fa'

import Aos from "aos";

const Hero = () => {

    useEffect(() => {
        Aos.init();
    }, []);

    return (
        <div className="hero">
            <Container>
                <div className="hero-wrapper">
                    <div className="hero-content">
                        <h5 data-aos="fade-right" data-aos-duration="1000" data-aos-delay="100">Hi, I'm Mehrojbek</h5>

                        <p className="typewriter-text">
                            <Typewriter
                                options={{
                                    strings: [
                                        'Frontend Developer',
                                        'Software Engineer',
                                        'Web Developer and Freelancer',
                                    ],
                                    autoStart: true,
                                    loop: true,
                                    delay: 100,
                                    deleteSpeed: 50,
                                }}
                            />
                        </p>
                        <p data-aos="fade-right" data-aos-duration="1200" data-aos-delay="700">
                            Based in Tashkent, Uzbekistan. I create bespoke websites to help people go further online. Show transformations you've made with websites, focusing on how your work improved the client's online presence.
                        </p>
                        <a data-aos="fade-right" data-aos-duration="1200" data-aos-delay="300" href={Resume} className="resume" download>Download Resume</a>

                        <div data-aos="fade-right" data-aos-duration="1200" data-aos-delay="900"     className="hero-socials">
                            <div className="socials">
                                <a className='social-link' href="https://github.com/mehrojalieev" target="_blank" rel="noreferrer"><FaGithub className='social-icon' /></a>
                                <a className='social-link' href="https://www.instagram.com/mehroj_alieev/" target="_blank" rel="noreferrer"><FaInstagram className='social-icon' /></a>
                                <a className='social-link' href="https://www.linkedin.com/in/mehrojbek-aliyev-805265284/" target="_blank" rel="noreferrer"><FaLinkedin className='social-icon' /></a>
                                <a className='social-link' href="https://t.me/mehroj220" target="_blank" rel="noreferrer"><FaTelegram className='social-icon' /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default Hero;
