import "./Contact.scss"
import { Container } from '../../utils/Utils'
import { FaGithub, FaInstagram, FaLinkedin, FaTelegram, FaTwitter } from "react-icons/fa";
import { FiPhone } from "react-icons/fi";
import { MdOutlineMail } from "react-icons/md";
import { IoLocationOutline } from "react-icons/io5";
import { BsSend } from "react-icons/bs";
import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

const telegramBotId = "6709048678:AAGQDQwL3Q2j9xKe1EMib--7rcr5spQw43w"
const chatId = 1755661523;

const Contact = () => {

  const [fullname, setFullname] = useState("")
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)
  const [email, setEmail] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [telegramUsername, setTelegramUsername] = useState("")
  const [message, setMessage] = useState("")

  const sendTelegram = async (e) => {
    e.preventDefault();

    setIsSubmitLoading(true)

    const fullMessage = `Ismi: ${fullname}\nEmail: ${email}\nusername: ${telegramUsername}\nNumber: ${phoneNumber}\nIzoh: ${message}`;


    const url = `https://api.telegram.org/bot${telegramBotId}/sendMessage`;
    const data = {
      chat_id: chatId,
      text: fullMessage,
    };

    try {
      // Send the POST request
      const response = await axios.post(url, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log(response.data);

      // setUName('');
      // setEmail('');
      // setMessage('');
    } catch (error) {
      console.error('Error sending message to Telegram:', error);
    }
  };


  useEffect(() => {
    if (isSubmitLoading) {
      setTimeout(() => {
        setIsSubmitLoading(false)
        setFullname("")
        setEmail("")
        setPhoneNumber("")
        setTelegramUsername("")
        setMessage("")
        toast.success("Your message has been sent successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      }, 3000)
    }
  }, [isSubmitLoading])

  return (
    <Container>
      <div id='contact' className="contact-wrapper">
        <h3 className="contact-title">Concact Me</h3>
        <div className="contact__actions-wrapper">
          <div className="contact-details">
            {/* <h3>Contact me</h3> */}
            <p>Fill out the form and our team will respond to you within 24 hours.</p>
            <a href="tel:+998978488006" className="detail-item">
              <i><FiPhone /></i>
              <p>+998 97 848 8006</p>
            </a>

            <a href="mailto:aliyevmehrojbek8006@gmail.com" className="detail-item">
              <i><MdOutlineMail /></i>
              <p>aliyevmehrojbek8006@gmail.com</p>
            </a>

            <a href="https://www.google.com/maps/place/Tashkent,+Uzbekistan" className="detail-item" target="_blank" rel="noopener noreferrer">
              <i><IoLocationOutline /></i>
              <p>Tashkent, Uzbekistan</p>
            </a>
          </div>
          <form onSubmit={sendTelegram} className="contact-form">
            <input required autoComplete="off" value={fullname} onChange={e => setFullname(e.target.value)} type="text" placeholder="Firstname and Lastname" />
            <input required autoComplete="off" value={email} onChange={e => setEmail(e.target.value)} type="email" placeholder="Email" />
            <input required autoComplete="off" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} type="number" placeholder="Phone number" />
            <input required autoComplete="off" value={telegramUsername} onChange={e => setTelegramUsername(e.target.value)} type="text" placeholder="Telegram username" />
            <textarea required value={message} onChange={e => setMessage(e.target.value)} placeholder="Comment...">

            </textarea>

            <button className={isSubmitLoading ? 'submitted-btn' : ''}  type="submit"  >
              {
                isSubmitLoading ? (
                  <div className="loader"></div>
                ) : (
                  <>
                    Send <i><BsSend /></i>
                  </>
                )}
            </button>
          </form>
        </div>
      </div>
    </Container>
  )
}

export default Contact
