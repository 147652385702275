import { Route, Routes } from "react-router-dom";
import "./App.scss"
import Nav from "./layout/nav/Nav";
import Home from "./pages/home/Home";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
function App() {
  return (
    <div className="App">
      <Nav/>
      <Routes >
        <Route path="/" element={<Home/>}/>
      </Routes>
      <ToastContainer />
     </div>
  );
}

export default App;
